import axios from "axios";

const URL = "https://630272629eb72a839d6fce07.mockapi.io"

const getListStudentService = () => axios.get(`${URL}/MyClassReactjs02`)

const createStudentService = (data) => axios.post(`${URL}/MyClassReactjs02`, data)

const getDetailStudentService = (id) => axios.get(`${URL}/MyClassReactjs02/${id}`)

const editStudentService = (payload) => axios.put(`${URL}/MyClassReactjs02/${payload.id}`, payload)

export {
    getListStudentService,
    createStudentService,
    getDetailStudentService,
    editStudentService,
}