import axios from "axios";

const url = {
    baseUrl: "https://630272629eb72a839d6fce07.mockapi.io",
    students: "MyClassReactjs02"
}

const instance = axios.create({
    baseURL: url.baseUrl,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
    },
});

const api = {
    url,
    instance,
    get: instance.get,
    post: instance.post,
    put: instance.put,
    delete: instance.delete,
};
export default api;