import HeaderComponent from "../Header";
import { Routes, Route } from 'react-router-dom';
import Home from "../../Pages/Home";
import About from "../../Pages/About";
import Contact from "../../Pages/Contact";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Students from "../../Pages/Students";
import MyClass from "../../Pages/MyClass";
import Account from "../../Pages/Account";

const DefaultLayout = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem('token');
        if(!token){
            navigate("login");
        }
    }, []);

    return(
        <div>
            <HeaderComponent/>
            <Routes>
                <Route path="/" element={<Home/>}></Route>
                <Route path="/about" element={<About/>}></Route>
                <Route path="/contact" element={<Contact/>}></Route>
                <Route path="/students" element={<Students/>}></Route>
                <Route path="/myclass" element={<MyClass/>}></Route>
                <Route path="/account" element={<Account/>}></Route>
            </Routes>
        </div>
    );
};
export default DefaultLayout;