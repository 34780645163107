import { INIT_STATE } from "./state";

import { SAVE_ALL_ACCOUNT } from "./constants";

import produce from "immer";

export default function accountReducer(state = INIT_STATE, action) {
    return produce(state, (draft) => { 
        switch (action.type) {
            case SAVE_ALL_ACCOUNT:
                draft.listAccount = action.payload;
                break;
            default:
                return state;
        }
    })
}