import { GET_ALL_STUDENT, SAVE_ALL_STUDENT, CREATE_STUDENT, SAVE_NEW_STUDENT, GET_DETAIL_STUDENT, SAVE_DETAIL_STUDENT, EDIT_STUDENT } from "./constants";

export function getAllStudentAction(payload) {
    return { type: GET_ALL_STUDENT, payload }
}

export function saveAllStudentAction(payload) {
    return { type: SAVE_ALL_STUDENT, payload }
}

export function createStudentAction(payload) {
    return { type: CREATE_STUDENT, payload }
}

export function saveNewStudentAction(payload) {
    return { type: SAVE_NEW_STUDENT, payload }
}

export const asyncCreateStudentAction = (dispatch) => (payload) =>
    new Promise((resolve) =>
        dispatch({ type: CREATE_STUDENT, payload, resolve })
    );

// {EDIT} HIỆN TẠI CHỈ DÙNG CHO NHỮNG CÁI PAGE KHÁC CÒN GIỜ TA DÙNG "ASYNC AWAIT CHO EDIT" BÊN DƯỚI
export function getDetailStudentAction(payload) {
    return { type: GET_DETAIL_STUDENT, payload }
}

export function saveDetailStudentAction(payload) {
    return { type: SAVE_DETAIL_STUDENT, payload }
}

// ĐỒNG BỘ CHO DATA VÀ FORM ĐỂ LOAD NAME
export const asyncDetailStudentAction = (dispatch) => (payload) =>
    new Promise((resolve) =>
        dispatch({ type: GET_DETAIL_STUDENT, payload, resolve })
    );

// ASYNC AWAIT CHO EDIT
export const asyncEditStudentAction = (dispatch) => (payload) =>
    new Promise((resolve) =>
        dispatch({ type: EDIT_STUDENT, payload, resolve })
    );