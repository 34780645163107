import { Layout, Menu } from 'antd';
import { Link } from "react-router-dom";
const { Header } = Layout;


const HeaderComponent = () => {
    return (
        <Header>
            <div className="logo" />
            <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={['2']}
            >
                <Menu.Item key={1}><Link to="/">Home</Link></Menu.Item>
                <Menu.Item key={2}><Link to="/about">About</Link></Menu.Item>
                <Menu.Item key={3}><Link to="/contact">Contact</Link></Menu.Item>
                <Menu.Item key={4}><Link to="/students">Students</Link></Menu.Item>
                <Menu.Item key={5}><Link to="/myclass">Class</Link></Menu.Item>
                <Menu.Item key={6}><Link to="/account">Account</Link></Menu.Item>
            </Menu> 
        </Header>
    );
}
export default HeaderComponent;