import { Button, Checkbox, Form, Input, notification } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { compose, mapProps } from "recompose";
import { asyncLoginAction } from './stores/action';

import { withTranslation, useTranslation } from "react-i18next";

const LoginComponent = (props) => {
    const { t } = props;
    const { i18n } = useTranslation();
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };
    const navigate = useNavigate();
    const onFinish = async (values) => {
        const { username, password } = values;
        console.log(values);
        const res = await props.doLogin(values);
        console.log(res);
        if (res) {
            // Username: admineh | Password: Estella1230
            notification.open({
                message: "Thông báo",
                description: "Đăng nhập thành công.",
                icon: <CheckOutlined style={{ color: "green" }} />
            });
            localStorage.setItem("token", res.result.token);
            navigate("/");
        } else {
            notification.open({
                message: "Thông báo",
                description: "Sai tên đăng nhập hoặc mật khẩu.",
                icon: <CloseOutlined style={{ color: "red" }} />
            });
        }
        //     if (username === "admin" && password === "admin") {
        //         notification.open({
        //             message: 'Thông báo',
        //             description:
        //                 'Đăng nhập thành công.',
        //             icon: (
        //                 <CheckOutlined
        //                     style={{
        //                         color: 'green',
        //                     }}
        //                 />
        //             ),
        //         });
        //         navigate("/");
        //         localStorage.setItem("user", username);
        //         console.log('Success:', values);
        //     } else {
        //         notification.open({
        //             message: 'Thông báo',
        //             description:
        //                 'Đăng nhập thất bại.',
        //             icon: (
        //                 <CloseOutlined
        //                     style={{
        //                         color: 'red',
        //                     }}
        //                 />
        //             ),
        //         });
        //         console.log('Failed:', values);
        //     }
        // console.log('Success:', values);
    };

    return (
        <>
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 8,
                }}
                style={{
                    marginTop: 30,
                }}
                // initialValues={{
                //     remember: true,
                // }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label={i18n.t("Username")}
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={i18n.t("Password")}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                {/* <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Checkbox>Remember me</Checkbox>
                </Form.Item> */}

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    {/* <div style={{ marginLeft: 400, display: 'inline-block' }}>
                        <button style={{minWidth: 50}} onClick={() => changeLanguage("en")}>Eng</button>
                        <button style={{minWidth: 50}} onClick={() => changeLanguage("vi")}>Vie</button>
                    </div> */}
                    <Form.Item
                    style={{ display: 'inline-block', minWidth: 50 }}
                        // wrapperCol={{
                        //     offset: 10,
                        //     span: 16,
                        // }}
                    >
                        <Button type="primary" htmlType="button" onClick={() => changeLanguage("en")} 
                            style={{marginLeft: 50, minWidth: 60}}
                        >
                            Eng
                        </Button>
                        <Button type="primary" htmlType="button" onClick={() => changeLanguage("vi")}
                            style={{marginLeft: 2, minWidth: 60}}
                        >
                            Vie
                        </Button>
                    </Form.Item>
                </Form.Item>
            </Form>
        </>
    );
};

const mapStateToProps = createStructuredSelector({

})

const mapDispatchToProps = (dispatch) => ({
    doLogin: (payload) => asyncLoginAction(dispatch)(payload),
})
// const withConnect = connect(mapStateToProps, mapDispatchToProps);
const login = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(LoginComponent),
);

// export default compose(withConnect)(Login);
export default login;