import './App.css';
import Login from './Pages/Login';
import DefaultLayout from './Components/DefaultLayout';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    (<div>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login/>}></Route> 
          <Route path='/*' element={<DefaultLayout/>}></Route> 
        </Routes>
      </BrowserRouter>
    </div>)
  );
}

export default App;
