import { Button, Checkbox, Form, Input, notification } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { compose, mapProps } from "recompose";
import { asyncLoginAction, getAllAccountAction } from './stores/action';
import { selectListAccount } from './stores/selector';
import { useEffect } from 'react';

const Account = (props) => {
    useEffect(() => {
        props.getAllAccountDispatch();
    }, []);
    
    return (
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 8,
            }}
            style={{
                marginTop: 30,
            }}
            // initialValues={{
            //     remember: true,
            // }}
            autoComplete="off"
        >
            <Form.Item
                label="Username"
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>

            {/* <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Checkbox>Remember me</Checkbox>
            </Form.Item> */}

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

const mapStateToProps = createStructuredSelector({
    listAccount: selectListAccount,
})

const mapDispatchToProps = (dispatch) => ({
    getAllAccountDispatch: (payload) => dispatch(getAllAccountAction(payload)),

    doLogin: (payload) => asyncLoginAction(dispatch)(payload),
})
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Account);