import { takeLatest, call, put } from "redux-saga/effects";
import { getAllAccountResidentService } from "../../../api/apiTest";
import { loginService } from "../../../api/login";
import { saveAllAccountAction } from "./action";
import { DO_LOGIN, GET_ALL_ACCOUNT } from "./constants";

function* doLogin({ payload, resolve }) {
    try {
        const response = yield call(loginService, payload);
        console.log(response);
        resolve(response.data);
    } catch (error) {
        resolve(null);
    }
}

function* getAllAccount({ payload }) {
    try {
        const response = yield call(getAllAccountResidentService, payload);
        yield put(saveAllAccountAction(response.data))
    } catch (error) {

    }
}

export function* doLoginSaga() {
    yield takeLatest(DO_LOGIN, doLogin);
}

export function* doGetAllAccountSaga() {
    yield takeLatest(GET_ALL_ACCOUNT, getAllAccount);
}