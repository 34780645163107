import { all } from "redux-saga/effects";
import { doGetAllAccountSaga, doLoginSaga,  } from "../Pages/Login/stores/sagas";
import { sagaStudent, getDetailStudentSaga, editStudentSaga } from "../Pages/MyClass/stores/sagas";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
    yield all([
        //student
        sagaStudent(),
        getDetailStudentSaga(),
        editStudentSaga(),
        doLoginSaga(),
        doGetAllAccountSaga(),
    ]);
}