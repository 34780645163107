import { DO_LOGIN, GET_ALL_ACCOUNT, SAVE_ALL_ACCOUNT } from "./constants";

export function getAllAccountAction(payload) {
    return { type: GET_ALL_ACCOUNT, payload }
}

export function saveAllAccountAction(payload) {
    return { type: SAVE_ALL_ACCOUNT, payload }
}

export const asyncLoginAction = (dispatch) => (payload) =>
    new Promise((resolve) =>
        dispatch({ type: DO_LOGIN, payload, resolve })
    );