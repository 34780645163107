import { INIT_STATE } from "./state";
import { createSelector } from "reselect";

const selectMyClass = (state) => state.studentReducer || INIT_STATE;

const selectStudents = createSelector(selectMyClass, (state) => state.students?.data || [[]]);
const selectNewStudent = createSelector(selectMyClass, (state) => state.newStudent || [[]]);
const selectDetailStudent = createSelector(selectMyClass, (state) => state.detailStudent || [[]]);

export {
    selectStudents,
    selectNewStudent,
    selectDetailStudent,
}