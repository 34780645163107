import { INIT_STATE } from "./state";

import { GET_ALL_STUDENT, SAVE_ALL_STUDENT, SAVE_DETAIL_STUDENT, SAVE_NEW_STUDENT } from "./constants";

import produce from "immer";

export default function studentReducer(state = INIT_STATE, action) {
    return produce(state, (draft) => { 
        switch (action.type) {
            case SAVE_ALL_STUDENT:
                draft.students.data = action.payload;
                break;
            case SAVE_NEW_STUDENT:
                draft.newStudent = action.payload;
                break;   
            case SAVE_DETAIL_STUDENT:
                draft.detailStudent = action.payload;
                break;
        
            default:
                return state;
        }
    })
}