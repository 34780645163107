const LOGIN = "login";
const DO_LOGIN = `${LOGIN}/doLogin`;

const GET_ALL_ACCOUNT = `${LOGIN}/getAllAccount`;
const SAVE_ALL_ACCOUNT = `${LOGIN}/saveAllAccount`;

export {
    LOGIN,
    DO_LOGIN,
    GET_ALL_ACCOUNT,
    SAVE_ALL_ACCOUNT,
}