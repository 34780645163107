import { takeLatest, put, call } from "redux-saga/effects";

import { getDetailStudentAction, saveAllStudentAction, saveDetailStudentAction, saveNewStudentAction } from "./action";

import { GET_ALL_STUDENT, CREATE_STUDENT, GET_DETAIL_STUDENT, EDIT_STUDENT } from "./constants";

import { getListStudentService, createStudentService, getDetailStudentService, editStudentService } from "../../../api/index";

function* fetchStudentSaga() {
    try {
        const response = yield call(getListStudentService)
        yield put(saveAllStudentAction(response.data))
        console.log(response);
    } catch (error) {

    }
}

function* createStudentSaga({ payload, resolve }) {
    try {
        const response = yield call(createStudentService, payload);
        console.log(response);
        resolve(response.status);
        yield put(saveNewStudentAction(response.data))
    } catch (error) {
        resolve(null);
    }
}

// {EDIT} HIỆN TẠI CHỈ DÙNG CHO NHỮNG CÁI PAGE KHÁC CÒN GIỜ TA DÙNG "ASYNC AWAIT CHO EDIT" BÊN DƯỚI
function* getDetailStudent({ payload, resolve }) {
    try {
        const response = yield call(getDetailStudentService, payload);
        // console.log(response);
        resolve(response.data);
        yield put(saveDetailStudentAction(response.data))
    } catch (error) {
        resolve(null);
    }
}

// ASYNC AWAIT CHO EDIT
function* editStudent({ payload, resolve }) {
    try {
        const response = yield call(editStudentService, payload);
        console.log(response);
        resolve(response);
    } catch (error) {
        resolve(null);
    }
}

export function* sagaStudent() {
    yield takeLatest(GET_ALL_STUDENT, fetchStudentSaga);
    yield takeLatest(CREATE_STUDENT, createStudentSaga);
}

// DETAIL SAGA - HIỆN TẠI CHỈ DÙNG CHO NHỮNG CÁI PAGE KHÁC CÒN GIỜ TA DÙNG "EDIT SAGA" BÊN DƯỚI
export function* getDetailStudentSaga() {
    yield takeLatest(GET_DETAIL_STUDENT, getDetailStudent);
}

// EDIT SAGA - KHAI BÁO EXPORT RIÊNG THÌ TA PHẢI QUA SAGAS.JS BÊN THƯ MỤC REDUX ĐỂ KHAI BÁO NÓ RA
export function* editStudentSaga() {
    yield takeLatest(EDIT_STUDENT, editStudent);
}