import api from "./api";

const getListStudentService = () => api.get(api.url.students).then((res) => res.data);

const getDetailStudentService = (id) => api.get(`${api.url.students}/${id}`).then((res) => res.data);

const createStudentService = (data) => api.post(api.url.students, data).then((res) => res.data);

const editStudentService = (id, data) => api.put(`${api.url.students}/${id}`, data).then((res) => res.data);

const deleteStudentService = (id) => api.delete(`${api.url.students}/${id}`).then((res) => res.data);

const studentService = {
    listStudent: getListStudentService,
    detailStudent: getDetailStudentService,
    addStudent: createStudentService,
    editStudent: editStudentService,
    deleteStudent: deleteStudentService,
}
export default studentService;