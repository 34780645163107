import './index.css';
import { Table, Space, Modal, Button, Form, Input, InputNumber, Row, Col } from 'antd';
import { useState, useEffect } from 'react';
import studentService from '../../Services/studentService';

// const data = [
//     {
//         key: "1",
//         name: "John Brown",
//         age: 32,
//         phone: "(926) 261-7162",
//         email: "John.Brown@gmail.com",
//     },
//     {
//         key: "2",
//         name: "Jim Green",
//         age: 42,
//         phone: "(253) 214-8457",
//         email: "Jim.Green@gmail.com",
//     },
//     {
//         key: "3",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "4",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "5",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "6",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "7",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "8",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },

//     {
//         key: "9",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "10",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "11",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },

//     {
//         key: "12",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "13",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "14",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "15",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "16",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "17",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "18",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "19",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "20",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "21",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "22",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "23",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "24",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "25",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "26",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "27",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "28",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "29",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
//     {
//         key: "30",
//         name: "Joe Black",
//         age: 32,
//         phone: "(748) 617-6568",
//         email: "Joe.Black@gmail.com",
//     },
// ];

const Students = () => {
    const [form] = Form.useForm();
    const [studentList, setStudentList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isShowKey, setIsShowKey] = useState(true);
    const [listImage, setListImage] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadListStudent();
    }, []);
    const loadListStudent = () => {
        setIsLoading(true);
        studentService.listStudent().then((res) => {
            console.log(res);
            if (res){
                setStudentList(res);
                setIsLoading(false);
            }
        })
    }

    const onDelete = (id) => {
        // const newList = studentList.filter(item => item.key !== id);
        // console.log(id);
        // setStudentList(newList);
        studentService.deleteStudent(id).then(res => {
            if(res){
                loadListStudent();
            }
        })
    };

    const onEdit = (id) => {
        // const studentEdit = studentList.find(item => item.key === id);
        // form.setFieldsValue({
        //     name: studentEdit.name,
        //     age: studentEdit.age,
        //     key: studentEdit.key,
        //     email: studentEdit.email,
        //     phone: studentEdit.phone
        // });
        // console.log(studentEdit);
        studentService.detailStudent(id).then((res) => {
            console.log(res);
            if (res) {
                form.setFieldsValue({
                    name: res.name,
                    age: res.age,
                    id: res.id,
                    email: res.email,
                    phone: res.phone
                });
            }
        });
        setIsModalVisible(true);
        setIsShowKey(true);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (value) => <div>{value}</div>
        },
        {
            title: 'Action',
            render: (text, record) => {
                return <Space>
                    <button onClick={() => onEdit(record.id)}>Edit</button>
                    <button onClick={() => onDelete(record.id)}>Delete</button>
                </Space>
            },
        },
    ];

    const createStudent = () => {
        form.resetFields();
        setIsModalVisible(true);
        setIsShowKey(false);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onSubmit = (values) => {
        // if (isShowKey) {
        //     const editStudent = studentList.map(item => { return item.key === values.key ? { ...item, ...values } : item });
        //     console.log(values);
        //     setStudentList(editStudent);
        //     alert("Sửa thành công. ");

        // } else {
        //     const newListStudent = [...studentList, values];
        //     setStudentList(newListStudent);
        //     alert("Thêm thành công. ");
        // }
        if (isShowKey) {
            //Sửa.
            console.log(values);
            studentService.editStudent(values.id, values).then(res => {
                console.log(res);
                if(res){
                    loadListStudent();
                }
            });
        }else{
            const params = {...values, gender: true, image: "https://benthanhtourist.com/uploads/images/hinh-suu-tam/thuy-dien/thuy-dien.jpg"}
            studentService.addStudent(params).then(res => {
                if(res){
                    loadListStudent();
                }
            });
        }
        form.resetFields();
        handleCancel();
    };

    const uploadImage = (event) => {
        console.log(URL.createObjectURL(event.target.files[0]));
        console.log(listImage);
        setListImage([...listImage, event.target.files[0]]);
    };

    const deleteImage = (i) => {
        //Cách 1:
        // console.log(i);
        // const newArr = listImage.filter((_, index) => index !== i);
        // setListImage(newArr);
        //------------------------------------------------------------
        // Cách 2: 
        setListImage([
            ...listImage.slice(0, i),
            ...listImage.slice(i + 1),
        ]);
    };

    return (
        <div>
            {/* <h1 className='color4'>This is Students page.</h1> */}
            <button onClick={createStudent}>Create student</button>
            <Table loading={isLoading} dataSource={studentList} columns={columns} rowKey="id" />;
            <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                footer={
                    <Space>
                        <Button onClick={handleCancel}>Đóng</Button>
                        <Button type='primary' htmlType='submit' form='from'>Gửi đi</Button>
                    </Space>
                }>
                <Form
                    form={form}
                    name="from"
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onSubmit}
                    autoComplete="off"
                    layout='vertical'
                >
                    <Form.Item label="id" name="id">
                        <Input disabled={isShowKey} />
                    </Form.Item>

                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please input your name!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Age"
                        name="age"
                        rules={[
                            {
                                required: true,
                                message: "Please input your age!",
                            },
                        ]}
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: "Please input your phone!",
                            },
                        ]}
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please input your email!",
                            },
                            {
                                pattern: new RegExp(
                                    "^[a-z][a-z0-9_.]{5,32}@[a-z0-9]{2,}(.[a-z0-9]{2,4}){1,2}$"
                                ),
                                message: "Email is not valid",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <div className="upload-btn-wrapper">
                        <button className="btn">Upload a file</button>
                        <input type="file" name="myfile" onChange={uploadImage} />
                    </div>
                    <br></br>
                    <Row gutter={24}>
                        {
                            listImage && listImage.map((item, index) =>
                                <Col xs={12} key={index}>
                                    <div className="container">
                                        <img src={URL.createObjectURL(item)} alt="Avatar" className="image" />
                                        <div className="middle">
                                            <div className="text" onClick={() => deleteImage(index)}>Xóa</div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};
export default Students;