const MY_CLASS = "myclass";//Riêng biệt, không được trùng.
const GET_ALL_STUDENT = `${MY_CLASS}/getAllStudent`;
const SAVE_ALL_STUDENT = `${MY_CLASS}/saveAllStudent`;

const CREATE_STUDENT = `${MY_CLASS}/createStudent`;
const SAVE_NEW_STUDENT = `${MY_CLASS}/saveNewStudent`;

const GET_DETAIL_STUDENT = `${MY_CLASS}/getDetailStudent`;
const SAVE_DETAIL_STUDENT = `${MY_CLASS}/saveDetailStudent`;

const EDIT_STUDENT = `${MY_CLASS}/editStudent`;

export {
    MY_CLASS,
    GET_ALL_STUDENT,
    SAVE_ALL_STUDENT,
    CREATE_STUDENT,
    SAVE_NEW_STUDENT,
    GET_DETAIL_STUDENT,
    SAVE_DETAIL_STUDENT,
    EDIT_STUDENT,
}