import { combineReducers } from "redux";
import studentReducer from "../Pages/MyClass/stores/reducer";
import accountReducer from "../Pages/Account/stores/reducer";

export default function createReducer() {
    const rootReducer = combineReducers({
        studentReducer,
        accountReducer,
    })
    return rootReducer;
}